import { createApp } from 'vue';
import App from './AppUser.vue';
import router from './router/user';
import Antd from 'ant-design-vue';
import { createPinia } from 'pinia';
import 'ant-design-vue/dist/reset.css';
import 'normalize.css/normalize.css';
import './assets/scss/redesign.scss';
import Notifications from '@kyvg/vue3-notification';

const pinia = createPinia();

createApp(App)
    .use(Antd)
    .use(router)
    .use(pinia)
    .use(Notifications)
    .mount('#app');
