import { createRouter, createWebHistory } from 'vue-router'

function loadView(view) {
  return () => import(/* webpackChunkName: "user-[request]" */ `../views/User/${view}.vue`);
}

const routes = [
  {
    path: '/user/signin',
    name: 'signin',
    component: loadView('LoginPage'),
    meta: { layout: 'user' }
  },
  {
    path: '/user/logout',
    name: 'logout',
    component: loadView('LogoutPage'),
    meta: { layout: 'user' }
  },
  {
    path: '/user/register',
    name: 'register',
    component: loadView('RegisterPage'),
    meta: { layout: 'user' }
  },
  {
    path: '/user/validate/:uuid',
    name: 'validate-user',
    component: loadView('ValidateUser'),
    meta: { layout: 'user' }
  },
  {
    path: '/user/twofactor',
    name: 'twofactor',
    component: loadView('TwofactorPage'),
    meta: { layout: 'user' }
  },
  {
    path: '/user/setpassword',
    name: 'setpassword',
    component: loadView('SetPasswordPage'),
    meta: { layout: 'user' }
  },
  {
    path: '/user/resetpassword/:uuid',
    name: 'resetpassword',
    component: loadView('SetPasswordPage'),
    meta: { layout: 'user' }
  },
  {
    path: '/user/forgotpassword',
    name: 'forgotpassword',
    component: loadView('ForgotPasswordPage'),
    meta: { layout: 'user' }
  },
  {
    path: '/error',
    name: 'error',
    component: loadView('NotFound'),
    meta: { layout: 'user' }
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/error'
  }
]
const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router
