<template>
    <notifications />
    <a-layout style="min-height: 100vh">
      <router-view />
    </a-layout>    
  </template>
  <script setup>   
  
  
  </script>
  <style lang="scss">
  @import '~normalize.css';
  @import 'assets/scss/redesign.scss';
  
  body {
    margin: 0;
  }
  </style>
  <style lang="scss" scoped>
  .content-layout {
    margin: 0px 20px;
  }
  
  .main-content {
    margin-left: 240px;
    background-color: #e3e9ef;
  
    @media (max-width:767px) {
      margin-left: 0;
    }
  }
  
  .bottom-banner {
    position: fixed;
    bottom: 0;
    left: 240px;
    width: 100%;
    background-color: coral;
    color: white;
    text-align: center;
    padding: 10px 0;
  }
  
  
  .report-bug {
    position: fixed;
    bottom: 50px;
    right: 15px;
    width: 50px;
    height: 50px;
    background-color: #E11313;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: none;
    box-shadow: rgb(0 0 0) 0px 5px 15px;
    cursor: pointer;
  }
  </style>